import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import rightImageUrl from '../../assets/home/we-are-here-illus.svg';

import * as messages from '../../locales/en.json';

const WeAreHereSection = () => {
  return (
    <section className="we-are-here-section">
      <img width="571" height="349" src={rightImageUrl} alt={messages['WeAreHereSection.Illus']} />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-7 col-md-12">
            <div className="content">
              <h2>{messages['WeAreHereSection.title']}</h2>
              <p>{ReactHtmlParser(messages['WeAreHereSection.desc'])}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeAreHereSection;
