import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import * as messages from '../../locales/en.json';

const QuotesSection = () => (
  <section className="quotes-section container">
    <div className="row">
      <div className="col-sm-12 quotes-section-description">
        <h3>{messages['quoteSection.title']}</h3>
      </div>
      {messages['quoteSection.quotes'].map(({ name, quote }) => (
        <div key={name} className="col-md-12 col-lg-4 quote-wrapper">
          <div className="quotes-section-quote">
            {ReactHtmlParser(quote)}
            <span className="author">{name}</span>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default QuotesSection;
