import React, { useState } from 'react';
import { string } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';

import ArrowIcon from '../../Icons/ArrowIcon';
import { black } from '../../../styles/base/_variables.scss';

const Accordion = ({ title, answer }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onMenuClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="accordion-item">
      <button
        type="button"
        className="accordion-item-header"
        ariaLabel="Open Accordion"
        onClick={onMenuClick}
      >
        <p>{title}</p>
        <ArrowIcon color={black} type={isDropdownOpen ? 'up' : 'down'} />
      </button>
      <div className={cn('accordion-item-content', { 'accordion-active': isDropdownOpen })}>
        <p>{ReactHtmlParser(answer)}</p>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: string.isRequired,
  answer: string.isRequired,
};

export default Accordion;
