import React from 'react';

import * as messages from '../../locales/en.json';
import Accordion from '../common/Accordion/Accordion';

const FAQSection = () => (
  <div className="home-faq container">
    <h3>{messages['faqSection.title']}</h3>
    {messages['faqSection.questions'].map(({ title, answer }, id) => (
      <Accordion key={id} title={title} answer={answer} />
    ))}
  </div>
);

export default FAQSection;
